<template>
  <v-app>
    <component :is="layout" />
  </v-app>
</template>

<script>
  // import '@/assets/app.css'

  export default {
    components: {
      'layout-public': () => import('@/layouts/public'),
      'layout-app': () => import('@/layouts/app'),
      'layout-maintenance': () => import('@/layouts/maintenance'),
    },
    computed: {
      layout() {
        if (process.env.VUE_APP_MAINTENANCE) {
          return 'layout-maintenance'
        } else {
          return 'layout-' + (this.$route.meta.layout || 'app')
        }
      },
    },
  }
</script>

<style scoped></style>
