import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth'
import app from '@/store/app'
import channel from '@/store/channel'
import merchandising from '@/store/merchandising'
import analytics from '@/store/analytics'
import permissions from './permissions'
import featureFlags from '@/store/featureFlags'
import shoppingGuide from '@/store/shoppingGuide'
import vuejsStorage from 'vuejs-storage'

Vue.use(Vuex)
Vue.use(vuejsStorage)

const store = new Vuex.Store({
  modules: {
    auth,
    app,
    channel,
    merchandising,
    analytics,
    permissions,
    featureFlags,
    shoppingGuide,
  },
  state: {},
  mutations: {},
  actions: {},
  plugins: [
    vuejsStorage({
      keys: ['featureFlags'],
      namespace: 'account21-state', // This configures the local storage key to use.
    }),
  ],
})

export default store
