import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
})

// init auth
store.commit('auth/init')

router.beforeEach((to, from, next) => {
  if (to.query?.feature) {
    store.commit('featureFlags/enableFeature', { feature: to.query.feature })
  }

  next()
})

// check if a auth is required
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && store.getters['auth/isAuthenticated'] === false) {
    store.commit('app/setLoginRedirectRoute', to.name)

    next('/login')
  } else if (to.meta.adminOnly && store.getters['auth/isAdmin'] === false) {
    // not allowed for regular user
    next(false)
  } else {
    next()
  }
})

export default router
