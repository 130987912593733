const state = () => ({})

const mutations = {}

const actions = {}

const getters = {
  hasAccountOwnerOptions: (state, getters, rootState, rootGetters) => {
    return (
      // Is the current logged in user the owner of the current channel ? if yes then he is account owner.
      rootGetters['auth/isAdmin'] ||
      rootState.channel.data?.customer?.email?.toLowerCase() ===
        rootGetters['auth/getLastUserName'].toLowerCase()
    )
  },

  canUseShoppingGuide: (state, getters, rootState) => {
    return rootState.channel.data.can_use_shopping_guide
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
