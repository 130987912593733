import apiClient from 'api-client'

const ID_AUTH_INFO = 'currentAuthInfo'

const state = () => ({
  token: null,
  roles: [],
  lastUserName: null,
})

const mutations = {
  init(state) {
    // restore auth info from local storage
    const currentAuthInfo = JSON.parse(localStorage.getItem(ID_AUTH_INFO))
    if (currentAuthInfo) {
      state.token = currentAuthInfo.token
      state.roles = currentAuthInfo.roles
    }

    state.lastUserName = localStorage.getItem('lastUsername')
  },
  setAuthorization(state, payload) {
    if (payload) {
      state.token = payload.token
      state.roles = payload.roles
      state.lastUserName = payload.username
    } else {
      state.token = null
      state.roles = []
      state.lastUserName = null
    }
  },
}

const actions = {
  loginUser({ commit }, [username, password]) {
    return new Promise((resolve, reject) => {
      apiClient.userLogin(username, password).then(
        response => {
          commit('setAuthorization', { ...response.data, username })

          localStorage.setItem(ID_AUTH_INFO, JSON.stringify(response.data))
          localStorage.setItem('lastUsername', username)

          resolve()
        },
        error => {
          reject(error)
        }
      )
    })
  },
  logoutUser({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient.userLogout().then(
        () => {
          commit('setAuthorization', null)
          localStorage.removeItem(ID_AUTH_INFO)

          resolve()
        },
        error => {
          reject(error)
        }
      )
    })
  },
}

const getters = {
  isAuthenticated: state => {
    return state.token !== null
  },
  isAdmin: state => {
    return state.roles.includes('ROLE_SUPER_ADMIN')
  },
  getLastUserName: state => {
    return state.lastUserName
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
