import axios from '@/plugins/axios'

function datesToPeriodParams(periodFrom, periodTo) {
  return {
    periodFrom: new Date(periodFrom).toISOString().slice(0, 10),
    periodTo: new Date(periodTo).toISOString().slice(0, 10),
  }
}

export default {
  getChannels() {
    return axios.get('/api/v1/shops').then(response => response.data)
  },
  getChannel(id) {
    return axios.get('/api/v1/shops/' + id).then(response => response.data)
  },
  putChannel(id, data) {
    return axios
      .put('/api/v1/shops/' + id, data)
      .then(response => response.data)
  },
  userLogin(username, password) {
    return axios
      .post('/api/v1/customer/login', {
        username,
        password,
      })
      .then(response => response.data)
  },
  userLogout() {
    return axios
      .delete('/api/v1/customer/logout')
      .then(response => response.data)
  },
  getFilterConfiguration(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/filterConfiguration')
      .then(response => response.data)
  },
  putFilterConfiguration(shopId, data) {
    return axios
      .put('/api/v1/shops/' + shopId + '/filterConfiguration', data)
      .then(response => response.data)
  },
  getFilterConfigurationSearch(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/filterConfiguration/search')
      .then(response => response.data)
  },
  putFilterConfigurationSearch(shopId, data) {
    return axios
      .put('/api/v1/shops/' + shopId + '/filterConfiguration/search', data)
      .then(response => response.data)
  },
  // For all operations dealing with filter names, the filter name must be encoded explicitly,
  // because it may contain trailing white space that is otherwise dropped implicitly by the HTTP client.
  // There doesn't seem to be any configuration to change this behavior on the client level.
  getFilterConfigurationSearchField(shopId, name) {
    return axios
      .get(
        '/api/v1/shops/' +
          shopId +
          '/filterConfiguration/search/' +
          encodeURIComponent(name)
      )
      .then(response => response.data)
  },
  putFilterConfigurationSearchField(shopId, name, data) {
    return axios
      .put(
        '/api/v1/shops/' +
          shopId +
          '/filterConfiguration/search/' +
          encodeURIComponent(name),
        data
      )
      .then(response => response.data)
  },
  getFilterConfigurationNavigation(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/filterConfiguration/navigation')
      .then(response => response.data)
  },
  putFilterConfigurationNavigation(shopId, data) {
    return axios
      .put('/api/v1/shops/' + shopId + '/filterConfiguration/navigation', data)
      .then(response => response.data)
  },
  getFilterConfigurationNavigationField(shopId, name) {
    return axios
      .get(
        '/api/v1/shops/' +
          shopId +
          '/filterConfiguration/navigation/' +
          encodeURIComponent(name)
      )
      .then(response => response.data)
  },
  putFilterConfigurationNavigationField(shopId, name, data) {
    return axios
      .put(
        '/api/v1/shops/' +
          shopId +
          '/filterConfiguration/navigation/' +
          encodeURIComponent(name),
        data
      )
      .then(response => response.data)
  },
  getFilterConfigurationNavigationCategory(shopId, category, useCategoryUrl) {
    const prefix = useCategoryUrl ? 'cat_url/' : 'cat/'
    return axios
      .get(
        '/api/v1/shops/' +
          shopId +
          '/filterConfiguration/' +
          prefix +
          encodeURIComponent(category)
      )
      .then(response => response.data)
  },
  putFilterConfigurationNavigationCategory(
    shopId,
    category,
    useCategoryUrl,
    data
  ) {
    const prefix = useCategoryUrl ? 'cat_url/' : 'cat/'
    return axios
      .put(
        '/api/v1/shops/' +
          shopId +
          '/filterConfiguration/' +
          prefix +
          encodeURIComponent(category),
        data
      )
      .then(response => response.data)
  },
  deleteFilterConfigurationNavigationCategory(
    shopId,
    category,
    useCategoryUrl
  ) {
    const prefix = useCategoryUrl ? 'cat_url/' : 'cat/'
    return axios
      .delete(
        '/api/v1/shops/' +
          shopId +
          '/filterConfiguration/' +
          prefix +
          encodeURIComponent(category)
      )
      .then(response => response.data)
  },
  getChannelCSAgent(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/customer-success/agent')
      .then(response => response.data)
  },
  getReportingDataSyncLastFull(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/reporting/dataSync/lastFull')
      .then(response => response.data)
  },
  getReportingDataSyncStats(shopId) {
    return axios.get('/api/v1/shops/' + shopId + '/import/stats')
  },
  getReportingDataSyncHistory(shopId, limit) {
    return axios
      .get('/api/v1/shops/' + shopId + '/import?limit=' + limit)
      .then(response => response.data)
  },
  getShortLivedTokenForCurrentFeed(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/import/feed/current/generateToken')
      .then(response => response.data)
  },
  getCurrentFeedUrl(shopId, token) {
    return `${axios.defaults.baseURL}/api/v1/shops/${shopId}/import/feed/current?token=${token}`
  },
  getShortLivedTokenForNewFeed(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/import/feed/new/generateToken')
      .then(response => response.data)
  },
  getNewFeedUrl(shopId, token) {
    return `${axios.defaults.baseURL}/api/v1/shops/${shopId}/import/feed/new?token=${token}`
  },
  getReportingSearchTerms(shopId, type, from, till, limit) {
    const params = {
      periodFrom: from,
      periodTo: till,
      count: limit,
    }
    return axios
      .get('/api/v1/shops/' + shopId + '/reporting/queries/' + type, {
        params: params,
      })
      .then(response => response.data)
  },
  postImportStart(shopId, deleteOldData, skipExport) {
    return axios
      .post('/api/v1/shops/' + shopId + '/import', {
        deleteOldData,
        skipExport,
      })
      .then(response => response.data)
  },
  getSupportStats(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/support/tickets/stats')
      .then(response => response.data)
  },
  getSupportKPI(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/support/kpis')
      .then(response => response.data)
      .then(data => {
        // 1. half of 20201
        // until we have a better solution
        data.data.meanReplyTimeMinutes = 168
        return data
      })
  },
  getMerchandisingCampaigns(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/campaigns')
      .then(response => response.data)
  },
  putMerchandisingCampaign(shopId, id, data) {
    return axios
      .put('/api/v1/shops/' + shopId + '/campaigns/' + id, data)
      .then(response => response.data)
  },
  postMerchandisingCampaign(shopId, data) {
    return axios
      .post('/api/v1/shops/' + shopId + '/campaigns', data)
      .then(response => response.data)
  },
  getMerchandisingPromotions(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/promotion')
      .then(response => response.data)
  },
  getMerchandisingPromotion(shopId, id) {
    return axios
      .get('/api/v1/shops/' + shopId + '/promotion/' + id)
      .then(response => response.data)
  },
  putMerchandisingPromotion(shopId, id, data) {
    return axios
      .put('/api/v1/shops/' + shopId + '/promotion/' + id, data)
      .then(response => response.data)
  },
  postMerchandisingPromotion(shopId, data) {
    return axios
      .post('/api/v1/shops/' + shopId + '/promotion', data)
      .then(response => response.data)
  },
  deleteMerchandisingPromotion(shopId, id) {
    return axios
      .delete('/api/v1/shops/' + shopId + '/promotion/' + id)
      .then(response => response.data)
  },
  getMerchandisingLandingPages(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/landingPage')
      .then(response => response.data)
  },
  getMerchandisingLandingPage(shopId, id) {
    return axios
      .get('/api/v1/shops/' + shopId + '/landingPage/' + id)
      .then(response => response.data)
  },
  putMerchandisingLandingPage(shopId, id, data) {
    return axios
      .put('/api/v1/shops/' + shopId + '/landingPage/' + id, data)
      .then(response => response.data)
  },
  postMerchandisingLandingPage(shopId, data) {
    return axios
      .post('/api/v1/shops/' + shopId + '/landingPage', data)
      .then(response => response.data)
  },
  deleteMerchandisingLandingPage(shopId, id) {
    return axios
      .delete('/api/v1/shops/' + shopId + '/landingPage/' + id)
      .then(response => response.data)
  },
  getMerchandisingSearchConcepts(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/search-concepts')
      .then(response => response.data)
  },
  getMerchandisingSearchConcept(shopId, id) {
    return axios
      .get('/api/v1/shops/' + shopId + '/search-concepts/' + id)
      .then(response => response.data)
  },
  putMerchandisingSearchConcept(shopId, id, data) {
    return axios
      .put('/api/v1/shops/' + shopId + '/search-concepts/' + id, data)
      .then(response => response.data)
  },
  postMerchandisingSearchConcept(shopId, data) {
    return axios
      .post('/api/v1/shops/' + shopId + '/search-concepts', data)
      .then(response => response.data)
  },
  deleteMerchandisingSearchConcept(shopId, id) {
    return axios
      .delete('/api/v1/shops/' + shopId + '/search-concepts/' + id)
      .then(response => response.data)
  },
  getMerchandisingPushRules(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/push-rules')
      .then(response => response.data)
  },
  getMerchandisingPushRule(shopId, id) {
    return axios
      .get('/api/v1/shops/' + shopId + '/push-rules/' + id)
      .then(response => response.data)
  },
  putMerchandisingPushRule(shopId, id, data) {
    return axios
      .put('/api/v1/shops/' + shopId + '/push-rules/' + id, data)
      .then(response => response.data)
  },
  postMerchandisingPushRule(shopId, data) {
    return axios
      .post('/api/v1/shops/' + shopId + '/push-rules', data)
      .then(response => response.data)
  },
  deleteMerchandisingPushRule(shopId, id) {
    return axios
      .delete('/api/v1/shops/' + shopId + '/push-rules/' + id)
      .then(response => response.data)
  },
  getMerchandisingProductPlacements(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/product-placements')
      .then(response => response.data)
  },
  getMerchandisingProductPlacement(shopId, id) {
    return axios
      .get('/api/v1/shops/' + shopId + '/product-placements/' + id)
      .then(response => response.data)
  },
  putMerchandisingProductPlacement(shopId, id, data) {
    return axios
      .put('/api/v1/shops/' + shopId + '/product-placements/' + id, data)
      .then(response => response.data)
  },
  postMerchandisingProductPlacement(shopId, data) {
    return axios
      .post('/api/v1/shops/' + shopId + '/product-placements', data)
      .then(response => response.data)
  },
  deleteMerchandisingProductPlacement(shopId, id) {
    return axios
      .delete('/api/v1/shops/' + shopId + '/product-placements/' + id)
      .then(response => response.data)
  },
  getGoogleAnalyticsRefreshToken(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/google-analytics/access-token')
      .then(response => response.data)
  },
  putGoogleAnalyticsRefreshToken(shopId, authorizationCode) {
    return axios
      .put('/api/v1/shops/' + shopId + '/google-analytics/refresh-token', {
        authorizationCode,
      })
      .then(response => response.data)
  },
  deleteGoogleAnalytics(shopId) {
    return axios
      .delete('/api/v1/shops/' + shopId + '/google-analytics')
      .then(response => response.data)
  },
  getTracking(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/tracking')
      .then(response => response.data)
  },
  patchTracking(shopId, trackingConfig) {
    return axios.patch('/api/v1/shops/' + shopId + '/tracking', trackingConfig)
  },
  getAttributes(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/attributes')
      .then(response => response.data)
  },
  getAttribute(shopId, attribute) {
    return axios
      .get('/api/v1/shops/' + shopId + '/attributes/' + attribute)
      .then(response => response.data)
  },
  getAttributesSuggest(shopId, attribute, query) {
    return axios
      .post(
        '/api/v1/shops/' + shopId + '/attributes/' + attribute + '/suggest',
        {
          query,
        }
      )
      .then(response => response.data)
  },
  getSynonyms(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/synonyms')
      .then(response => response.data)
  },
  postSynonyms(shopId, word, synonym) {
    return axios
      .post('/api/v1/shops/' + shopId + '/synonyms', {
        word: word,
        replaceWord: synonym,
      })
      .then(response => response.data)
  },
  getSynonym(shopId, id) {
    return axios
      .get('/api/v1/shops/' + shopId + '/synonyms/' + id)
      .then(response => response.data)
  },
  putSynonym(shopId, id, word, synonym) {
    return axios
      .put('/api/v1/shops/' + shopId + '/synonyms/' + id, {
        id: id,
        word: word,
        replaceWord: synonym,
      })
      .then(response => response.data)
  },
  deleteSynonym(shopId, id) {
    return axios
      .delete('/api/v1/shops/' + shopId + '/synonyms/' + id)
      .then(response => response.data)
  },
  postSynonymImport(shopId, file, onUploadProgress) {
    const formData = new FormData()
    formData.append('csv_file', file)

    return axios
      .post('/api/v1/shops/' + shopId + '/synonyms/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      })
      .then(response => response.data)
  },
  getSplitProcessors(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/split-processors')
      .then(response => response.data)
  },
  postSplitProcessor(shopId, word, synonym) {
    return axios
      .post('/api/v1/shops/' + shopId + '/split-processors', {
        word: word,
        replaceWord: synonym,
      })
      .then(response => response.data)
  },
  getSplitProcessor(shopId, id) {
    return axios
      .get('/api/v1/shops/' + shopId + '/split-processors/' + id)
      .then(response => response.data)
  },
  putSplitProcessor(shopId, id, word, synonym) {
    return axios
      .put('/api/v1/shops/' + shopId + '/split-processors/' + id, {
        id: id,
        word: word,
        replaceWord: synonym,
      })
      .then(response => response.data)
  },
  deleteSplitProcessor(shopId, id) {
    return axios
      .delete('/api/v1/shops/' + shopId + '/split-processors/' + id)
      .then(response => response.data)
  },
  getColors(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/colors')
      .then(response => response.data)
  },
  postColors(shopId, name, code) {
    return axios
      .post('/api/v1/shops/' + shopId + '/colors', {
        name: name,
        hexcode: code,
        shopId: shopId,
      })
      .then(response => response.data)
  },
  getColor(shopId, id) {
    return axios
      .get('/api/v1/shops/' + shopId + '/color/' + id)
      .then(response => response.data)
  },
  putColor(shopId, id, name, code) {
    return axios
      .put('/api/v1/shops/' + shopId + '/colors/' + id, {
        name: name,
        hexcode: code,
        id: id,
        shopId: shopId,
      })
      .then(response => response.data)
  },
  deleteColor(shopId, id) {
    return axios
      .delete('/api/v1/shops/' + shopId + '/colors/' + id)
      .then(response => response.data)
  },
  getUsers(customerId) {
    return axios
      .get('/api/v1/account/' + customerId + '/users')
      .then(response => response.data)
  },
  postUser(customerId, email) {
    return axios
      .post('api/v1/account/' + customerId + '/users/' + email, {
        email: email,
      })
      .then(response => response.data)
  },
  deleteUser(customerId, email) {
    return axios
      .delete('/api/v1/account/' + customerId + '/users/' + email)
      .then(response => response.data)
  },
  getUser(customerId, id) {
    return axios
      .get('/api/v1/account/' + customerId + '/users/' + id)
      .then(response => response.data)
  },
  postPermissions(customerId, email, shopId, arrayOfPermissions) {
    return axios
      .post(
        'api/v1/account/' + customerId + '/permissions/' + email + '/' + shopId,
        arrayOfPermissions
      )
      .then(response => response.data)
  },
  getPermissions(customerId, shopId) {
    return axios
      .get('api/v1/account/' + customerId + '/permissions/shops/' + shopId)
      .then(response => response.data)
  },
  getDictionaryItems(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/dictionaryItems')
      .then(response => response.data)
  },
  postDictionaryItem(shopId, dictionaryItem) {
    return axios
      .post('/api/v1/shops/' + shopId + '/dictionaryItems', dictionaryItem)
      .then(response => response.data)
  },
  getDictionaryItem(shopId, id) {
    return axios
      .get('/api/v1/shops/' + shopId + '/dictionaryItems/' + id)
      .then(response => response.data)
  },
  putDictionaryItem(shopId, id, dictionaryItem) {
    return axios
      .put('/api/v1/shops/' + shopId + '/dictionaryItems/' + id, dictionaryItem)
      .then(response => response.data)
  },
  deleteDictionaryItem(shopId, id) {
    return axios
      .delete('/api/v1/shops/' + shopId + '/dictionaryItems/' + id)
      .then(response => response.data)
  },
  getLegacyShoppingGuides(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/legacy-shopping-guides')
      .then(response => response.data)
  },
  getLegacyShoppingGuide(shopId, shoppingGuideName) {
    return axios
      .get(
        '/api/v1/shops/' +
          shopId +
          '/legacy-shopping-guides/' +
          shoppingGuideName
      )
      .then(response => response.data)
  },
  postLegacyShoppingGuide(shopId, configuration) {
    return axios
      .post(
        '/api/v1/shops/' + shopId + '/legacy-shopping-guides',
        configuration
      )
      .then(response => response.data)
  },
  putLegacyShoppingGuide(shopId, shoppingGuideName, configuration) {
    return axios
      .put(
        '/api/v1/shops/' +
          shopId +
          '/legacy-shopping-guides/' +
          shoppingGuideName,
        configuration
      )
      .then(response => response.data)
  },
  deleteLegacyShoppingGuide(shopId, shoppingGuideName) {
    return axios
      .delete(
        '/api/v1/shops/' +
          shopId +
          '/legacy-shopping-guides/' +
          shoppingGuideName
      )
      .then(response => response.data)
  },
  getLegacyShoppingGuideQuestions(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/legacy-shopping-guides/_empty')
      .then(response => response.data)
  },
  getSmartSuggest(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/smartSuggest')
      .then(response => response.data)
  },
  putSmartSuggest(shopId, data) {
    return axios
      .put('/api/v1/shops/' + shopId + '/smartSuggest', data)
      .then(response => response.data)
  },
  getPlentymarketsConfig(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/import/plentymarkets-config')
      .then(response => response.data)
  },
  putPlentymarketsConfig(shopId, data) {
    return axios
      .put('/api/v1/shops/' + shopId + '/import/plentymarkets-config', data)
      .then(response => response.data)
  },
  sendPasswordMail(email) {
    return axios
      .post('/api/v1/forgot-password/', {
        email,
      })
      .then(response => response.data)
  },
  resetPassword(email, password, passwordConfirmation, token) {
    return axios
      .post('/api/v1/reset-password/', {
        email,
        token,
        password,
        password_confirmation: passwordConfirmation,
      })
      .then(response => response.data)
  },
  getAccountBillings(customerId) {
    return axios
      .get('/api/v1/account/' + customerId + '/billings')
      .then(response => response.data)
  },
  getAccountBillingPDF(customerId, id) {
    return axios
      .get('/api/v1/account/' + customerId + '/billings/pdf/' + id)
      .then(response => response.data)
  },
  getHealthCheck() {
    return axios.get('/api/v1/healthcheck').then(response => response.data)
  },
  getVirtualCategories(shopId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/virtualCategory')
      .then(response => response.data)
  },
  getVirtualCategory(shopId, categoryId) {
    return axios
      .get('/api/v1/shops/' + shopId + '/virtualCategory/' + categoryId)
      .then(response => response.data)
  },
  postVirtualCategory(shopId, data) {
    return axios
      .post('/api/v1/shops/' + shopId + '/virtualCategory', data)
      .then(response => response.data)
  },
  putVirtualCategory(shopId, categoryId, data) {
    return axios
      .put('/api/v1/shops/' + shopId + '/virtualCategory/' + categoryId, data)
      .then(response => response.data)
  },
  deleteVirtualCategory(shopId, categoryId) {
    return axios
      .delete('/api/v1/shops/' + shopId + '/virtualCategory/' + categoryId)
      .then(response => response.data)
  },
  getResponseTime(date) {
    return axios
      .get('/api/v1/reporting/responseTime?date=' + date)
      .then(response => response.data)
  },
  getImportLog(shopId, importJobId) {
    return axios
      .get(`/api/v1/shops/${shopId}/reporting/dataSync/${importJobId}/log`)
      .then(response => response.data)
  },
  cancelImport(shopId, importJobId) {
    return axios
      .delete(`/api/v1/shops/${shopId}/import/${importJobId}`)
      .then(response => response.data)
  },
  downloadLogs(shopId, importJobId) {
    return axios
      .get(`/api/v1/shops/${shopId}/reporting/dataSync/${importJobId}/log`, {
        headers: { Accept: 'text/csv' },
      })
      .then(response => response.data)
  },
  getImportNotificationRecipients(shopId) {
    return axios
      .get(`/api/v1/shops/${shopId}/import/notificationRecipients`)
      .then(response => response.data)
  },
  postImportNotificationRecipient(shopId, data) {
    return axios
      .post(`/api/v1/shops/${shopId}/import/notificationRecipients`, data)
      .then(response => response.data)
  },
  deleteImportNotificationRecipient(shopId, recipientId) {
    return axios
      .delete(
        `/api/v1/shops/${shopId}/import/notificationRecipients/${recipientId}`
      )
      .then(response => response.data)
  },
  getBlockedQueries(shopId) {
    return axios
      .get(`/api/v1/shops/${shopId}/blocked-queries`)
      .then(response => response.data)
  },
  postBlockedQuery(shopId, blockedQuery) {
    return axios
      .post(`/api/v1/shops/${shopId}/blocked-queries`, blockedQuery)
      .then(response => response.data)
  },
  getBlockedQuery(shopId, id) {
    return axios
      .get(`/api/v1/shops/${shopId}/blocked-queries/${id}`)
      .then(response => response.data)
  },
  putBlockedQuery(shopId, id, blockedQuery) {
    return axios
      .put(`/api/v1/shops/${shopId}/blocked-queries/${id}`, blockedQuery)
      .then(response => response.data)
  },
  deleteBlockedQuery(shopId, id) {
    return axios
      .delete(`/api/v1/shops/${shopId}/blocked-queries/${id}`)
      .then(response => response.data)
  },
  getTrbo(shopId) {
    return axios
      .get(`/api/v1/shops/${shopId}/trbo`)
      .then(response => response.data)
  },
  postTrbo(shopId, trbo) {
    return axios
      .post(`/api/v1/shops/${shopId}/trbo`, trbo)
      .then(response => response.data)
  },
  getInstantFrontend(shopId) {
    return axios
      .get(`/api/v1/shops/${shopId}/instant-frontend`)
      .then(response => response.data)
  },
  postInstantFrontend(shopId, data) {
    return axios
      .post(`/api/v1/shops/${shopId}/instant-frontend`, data)
      .then(response => response.data)
  },
  getInstantFrontendShoppingGuide(shopId) {
    return axios
      .get(`/api/v1/shops/${shopId}/instant-frontend/shopping-guide`)
      .then(response => response.data)
  },
  postInstantFrontendShoppingGuide(shopId, data) {
    return axios
      .post(`/api/v1/shops/${shopId}/instant-frontend/shopping-guide`, data)
      .then(response => response.data)
  },
  getPersonalAccessTokens() {
    return axios
      .get('/api/v1/customer/personalAccessTokens')
      .then(response => response.data)
  },
  postPersonalAccessTokens(data) {
    return axios
      .post('/api/v1/customer/personalAccessTokens', data)
      .then(response => response.data)
  },
  deletePersonalAccessToken(id) {
    return axios
      .delete(`/api/v1/customer/personalAccessTokens/${id}`)
      .then(response => response.data)
  },
  getConversionRateByDevice(shopId, periodFrom, periodTo) {
    return axios
      .get(
        `/api/v1/shops/${shopId}/google-analytics/conversion-rate/by-device`,
        {
          params: datesToPeriodParams(periodFrom, periodTo),
        }
      )
      .then(response => response.data)
  },
  getConversionRateWithAndWithoutLisa(shopId, periodFrom, periodTo) {
    return axios
      .get(
        `/api/v1/shops/${shopId}/google-analytics/conversion-rate/by-feature/lisa`,
        {
          params: datesToPeriodParams(periodFrom, periodTo),
        }
      )
      .then(response => response.data)
  },
  getLisaTrackingStatus(shopId, periodFrom, periodTo) {
    return axios
      .get(`/api/v1/shops/${shopId}/google-analytics/status/by-feature/lisa`, {
        params: datesToPeriodParams(periodFrom, periodTo),
      })
      .then(response => response.data)
  },
  getLisaRevenue(shopId, periodFrom, periodTo) {
    return axios
      .get(`/api/v1/shops/${shopId}/google-analytics/revenue/by-feature/lisa`, {
        params: datesToPeriodParams(periodFrom, periodTo),
      })
      .then(response => response.data)
  },
  getAnalyticsTopQueries(shopId, periodFrom, periodTo, limit) {
    return axios
      .get(`/api/v1/shops/${shopId}/google-analytics/queries/top`, {
        params: {
          ...datesToPeriodParams(periodFrom, periodTo),
          count: limit,
        },
      })
      .then(response => response.data)
  },
  isTransactionTrackingWorking(shopId) {
    return axios
      .get(`/api/v1/shops/${shopId}/google-analytics/status/transactions`)
      .then(response => response.data)
  },
  getAnalyticsAccountsV3(shopId) {
    return axios
      .get(`/api/v1/shops/${shopId}/google-analytics/v3/accounts`)
      .then(response => response.data)
  },
  getAnalyticsPropertiesV3(shopId) {
    return axios
      .get(`/api/v1/shops/${shopId}/google-analytics/v3/properties`)
      .then(response => response.data)
  },
  getAnalyticsAccountsV4(shopId) {
    return axios
      .get(`/api/v1/shops/${shopId}/google-analytics/v4/accounts`)
      .then(response => response.data)
  },
  getAnalyticsPropertiesV4(shopId, accountId) {
    return axios
      .get(`/api/v1/shops/${shopId}/google-analytics/v4/properties`, {
        params: { accountId },
      })
      .then(response => response.data)
  },
  getShoppingGuides(shopId) {
    return axios
      .get(`/api/v1/shops/${shopId}/shopping-guides`)
      .then(response => response.data)
  },
  getShoppingGuide(shopId, guideId) {
    return axios
      .get(`/api/v1/shops/${shopId}/shopping-guides/${guideId}`)
      .then(response => response.data)
  },
  addShoppingGuide(shopId, config) {
    return axios
      .post(`/api/v1/shops/${shopId}/shopping-guides`, config)
      .then(response => response.data)
  },
  saveShoppingGuide(shopId, config, guideId) {
    return axios
      .patch(`/api/v1/shops/${shopId}/shopping-guides/${guideId}`, config)
      .then(response => response.data)
  },
  deleteShoppingGuide(shopId, guideId) {
    return axios
      .delete(`/api/v1/shops/${shopId}/shopping-guides/${guideId}`)
      .then(response => response.data)
  },
  getShoppingGuideAttributesForQuestions(shopId) {
    return axios
      .get(`/api/v1/shops/${shopId}/shopping-guides/attributes-for-questions`)
      .then(response => response.data)
  },
  getReportMailRecipients(shopId) {
    return axios
      .get(`/api/v1/shops/${shopId}/reporting/report-mail-recipients`)
      .then(response => response.data)
  },
  addReportMailRecipient(shopId, data) {
    return axios
      .post(`/api/v1/shops/${shopId}/reporting/report-mail-recipients`, data)
      .then(response => response.data)
  },
  deleteReportMailRecipient(shopId, recipientId) {
    return axios
      .delete(
        `/api/v1/shops/${shopId}/reporting/report-mail-recipients/${recipientId}`
      )
      .then(response => response.data)
  },
  unsubscribe(token) {
    return axios.get(`/api/v1/unsubscribe?token=${token}`)
  },
}
