const state = () => ({})

const mutations = {}

const actions = {}

const getters = {
  isCampaignLive: () => campaign => {
    // TODO sollte direkt vom server kommen !

    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const startsInThePast =
      campaign.firstDay && Date.parse(campaign.firstDay) <= Date.now()
    const endsInTheFutureOrNever =
      !campaign.lastDay || Date.parse(campaign.lastDay) >= yesterday.valueOf()
    const startAndEndDateUndefined = !campaign.firstDay && !campaign.lastDay

    return (
      (startsInThePast && endsInTheFutureOrNever) || startAndEndDateUndefined
    )
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
