import apiClient from 'api-client'
import router from '@/router'
import i18n from '@/i18n'
import moment from 'moment/moment'
import store from './index'

// FACADE PATTERN

const ID_LAST_CHANNEL = 'lastChannel'
const ID_REDIRECT_ROUTE = 'redirectRoute'
const ID_LOCALE = 'locale'

const state = () => ({
  notification: {
    message: '',
    type: '',
  },
  channels: [],
  isLoading: true,
  locale: localStorage.getItem(ID_LOCALE) || 'en',
})

const mutations = {
  notification(state, [message, type]) {
    state.notification = {
      message,
      type,
    }
  },
  setChannels(state, payload) {
    state.channels = payload
  },
  channelView(state, id) {
    state.channelView = id
  },
  isLoading(state, payload) {
    state.isLoading = payload
  },
  setLoginRedirectRoute(state, payload) {
    localStorage.setItem(ID_REDIRECT_ROUTE, payload)
  },
  setLocale(state, payload) {
    state.locale = payload
    localStorage.setItem(ID_LOCALE, payload)
    i18n.locale = payload
    moment.locale(store.state.app.locale)
  },
}

const actions = {
  login({ commit, getters, dispatch }, [username, password]) {
    commit('isLoading', true)
    return new Promise((resolve, reject) => {
      dispatch('auth/loginUser', [username, password], { root: true }).then(
        () => {
          // goto last route
          const lastRoute = localStorage.getItem(ID_REDIRECT_ROUTE)
          const goto = {
            name: lastRoute || 'Dashboard',
            params: {},
          }

          // auto switch to last active channel
          if (getters.getLastChannel) {
            goto.params = { channelId: getters.getLastChannel }
          }

          // navigate
          router.push(goto).then(() => {
            localStorage.removeItem(ID_REDIRECT_ROUTE)
            resolve()
          })
        },
        error => {
          reject(error)
        }
      )
    })
  },
  logout({ commit, dispatch }) {
    commit('app/isLoading', true, { root: true })

    return new Promise((resolve, reject) => {
      dispatch('auth/logoutUser', null, { root: true }).then(
        () => {
          router.push('/login').then(() => {
            resolve()
          })
        },
        error => {
          reject(error)
        }
      )
    })
  },
  loadChannels({ commit }) {
    return apiClient
      .getChannels()
      .then(response => {
        commit('setChannels', response.data)
      })
      .catch(() => {
        commit('notification', ['load channels failed', 'error'])
      })
  },
  loadChannel({ commit, dispatch, rootState }, id) {
    commit('isLoading', true)

    return dispatch('channel/load', id, { root: true })
      .then(() => {
        console.log('channel loading done')

        // update zendesk widget
        const channel = rootState.channel.data
        try {
          this._vm.$zendesk.prefill({
            name: channel.customer.email,
            email: channel.customer.email,
          })
          this._vm.$zendesk.show()
        } catch (e) {
          console.warn(e)
        }
      })
      .catch(e => {
        console.warn(e)
        commit('notification', ['switch channel failed', 'error'])
      })
      .then(() => {
        commit('isLoading', false)
      })
  },
  // eslint-disable-next-line no-unused-vars
  switchChannel({ commit }, id) {
    const currentRoute = router.currentRoute
    currentRoute.params.channelId = id
    router.push(currentRoute)

    localStorage.setItem(ID_LAST_CHANNEL, id)
  },
}

const getters = {
  getNumberFormatter: () => options => {
    return new Intl.NumberFormat(i18n.locale, options)
  },
  getLastChannel: () => {
    return localStorage.getItem(ID_LAST_CHANNEL)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
