import axios from 'axios'
import store from '@/store'
import router from '@/router'

const fetchClient = () => {
  const defaultOptions = {
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }

  // Create instance
  const instance = axios.create(defaultOptions)

  // Add a request interceptor
  instance.interceptors.request.use(config => {
    // Do something before request is sent

    // Add the AUTH token for any request
    const token = store.state.auth.token
    if (token) {
      config.headers.common.token = token
    }

    // Add selected language to request so the response is localized.
    const locale = store.state.app.locale
    if (locale) {
      config.headers.common['Accept-Language'] = locale
    }

    return config
  })

  // Add a response interceptor
  instance.interceptors.response.use(null, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.status === 401) {
      console.warn('token expired')

      // TODO find better way?
      router.push('/login')
    } else if (error.response.status === 403 && store.state.auth.token) {
      // access not allowed
      store.commit('app/notification', ['access not allowed', 'error'])

      router.push('/')
    }

    return Promise.reject(error)
  })

  return instance
}

export default fetchClient()
