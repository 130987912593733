const channelPrefix = '/:channelId(\\d+)'

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../views/Reset'),
    meta: {
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import('../views/Unsubscribe'),
    meta: {
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: '/healthcheck',
    name: 'HealthCheck',
    component: () => import('../views/HealthCheck'),
    meta: {
      layout: 'public',
      requiresAuth: false,
    },
  },
  {
    path: `${channelPrefix}/`,
    name: 'Dashboard',
    component: () => import('../views/Home'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/reports/search`,
    name: 'Search',
    component: () => import('../views/Reports/Search'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/search-navigation/synonyms`,
    name: 'Synonyms',
    component: () => import('../views/SearchNavigation/Synonyms'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/search-navigation/preprocessor`,
    name: 'Preprocessor',
    component: () => import('../views/SearchNavigation/Preprocessor'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/search-navigation/dictionary-items`,
    name: 'Dictionary Items',
    component: () => import('../views/SearchNavigation/DictionaryItems'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/search-navigation/synchronization`,
    name: 'Data synchronization',
    component: () => import('../views/SearchNavigation/Synchronization'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/search-navigation/plentymarkets-config`,
    name: 'PlentymarketsConfig',
    component: () => import('../views/SearchNavigation/PlentymarketsConfig'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/search-navigation/virtual-categories`,
    name: 'DataVirtualCategories',
    component: () => import('../views/SearchNavigation/VirtualCategories'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/filter/search`,
    name: 'FilterConfigurationSearch',
    component: () => import('../views/FilterConfiguration/Search'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/filter/navigation`,
    name: 'FilterConfigurationNavigation',
    component: () => import('../views/FilterConfiguration/Navigation'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/filter/colors`,
    name: 'FilterConfigurationColors',
    component: () => import('../views/FilterConfiguration/Colors'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/filter/logos`,
    name: 'FilterConfigurationLogos',
    component: () => import('../views/FilterConfiguration/Logos'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/merchandising/landingpages`,
    name: 'Merchandising - Landingpages',
    component: () => import('../views/Merchandising/Landingpages'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${channelPrefix}/merchandising/campaigns`,
    name: 'MerchandisingCampaigns',
    component: () => import('../views/Merchandising/Campaigns'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${channelPrefix}/merchandising/promotions`,
    name: 'MerchandisingPromotions',
    component: () => import('../views/Merchandising/Promotions'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${channelPrefix}/merchandising/product-placements`,
    name: 'MerchandisingProductPlacements',
    component: () => import('../views/Merchandising/ProductPlacements'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${channelPrefix}/merchandising/search-concepts`,
    name: 'MerchandisingSearchConcepts',
    component: () => import('../views/Merchandising/SearchConcepts'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${channelPrefix}/merchandising/product-pushing`,
    name: 'MerchandisingProductPushing',
    component: () => import('../views/Merchandising/ProductPushing'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${channelPrefix}/modules/legacy-shopping-guide`,
    name: 'AddonsLegacyShoppingGuide',
    component: () => import('../views/Merchandising/LegacyShoppingGuide'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `${channelPrefix}/search-insights`,
    name: 'SearchInsights',
    component: () => import('../views/SearchNavigation/SearchInsights'),
    meta: {
      requiresAuth: true,
      // Route is not marked admin-only, because it's hidden from the menu, but should be accessible to
      // customers who have been given a link to it.
    },
  },
  {
    path: `${channelPrefix}/account/invoices`,
    name: 'AccountInvoices',
    component: () => import('../views/Account/Invoices'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/account/personal-access-tokens`,
    name: 'AccountPersonalAccessTokens',
    component: () => import('../views/Account/PersonalAccessTokens'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/tracking/analytics`,
    name: 'TrackingAnalytics',
    component: () => import('../views/Tracking/Analytics'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/modules/smart-suggest`,
    name: 'ModulesSmartSuggest',
    component: () => import('../views/Modules/SmartSuggest'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/modules/assisted-suggest`,
    name: 'ModulesAssistedSuggest',
    component: () => import('../views/Modules/AssistedSuggest'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/modules/mobile-smart-suggest`,
    name: 'ModulesMobileSmartSuggest',
    component: () => import('../views/Modules/MobileSmartSuggest'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/instant-frontend/lisa`,
    name: 'ModulesLiSA',
    component: () => import('../views/InstantFrontend/Lisa'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/instant-frontend/product-list-page`,
    name: 'InstantFrontendProductListPage',
    component: () => import('../views/InstantFrontend/ProductListPage'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/instant-frontend/shopping-guide`,
    name: 'ShoppingGuide',
    component: () => import('../views/InstantFrontend/ShoppingGuide'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/instant-frontend/shopping-guide/edit/:guideId(\\d+)?`,
    name: 'ShoppingGuideEdit',
    component: () => import('../views/InstantFrontend/ShoppingGuide/Edit'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/sandbox`,
    name: 'Sandbox',
    component: () => import('../views/Sandbox'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/account/user-management`,
    name: 'AccountUsers',
    component: () => import('../views/Account/User-Management'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/report-mail`,
    name: 'ReportMail',
    component: () => import('../views/Account/ReportMail'),
    meta: { requiresAuth: true },
  },
  {
    path: `${channelPrefix}/trbo/recommendation`,
    name: 'TrboRecommendation',
    component: () => import('../views/Trbo/Recommendations'),
    meta: { requiresAuth: true },
  },
]
