export const FEATURES = {
  INSTANT_FRONTEND: 'Instant Frontend',
}

const state = () => ({})

const mutations = {
  enableFeature(state, payload) {
    state[payload.feature] = true
  },
  disableFeature(state, payload) {
    delete state[payload.feature]
  },
}

const actions = {}

const getters = {
  availableFeatures: () => Object.keys(FEATURES),
  enabledFeatures: state => Object.keys(state),
  isFeatureEnabled: state => feature => !!state[feature],
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
