import apiClient from 'api-client'

const state = () => ({
  id: null,
  data: [],
  importStatus: {},
  csAgent: {
    email: '',
    name: '',
    phone: '',
    photoUrl: '',
  },
})

const mutations = {
  setData(state, [id, data]) {
    state.id = id
    state.data = data
  },
  setCSAgent(state, payload) {
    state.csAgent = payload
  },
  setImportStatus(state, payload) {
    state.importStatus = payload
  },
}

const actions = {
  load({ commit, getters, state, dispatch }, id) {
    // load channel data
    const loaderChannel = apiClient
      .getChannel(id)
      .then(channel => {
        commit('setData', [id, channel.data])
      })
      .catch(() => {
        commit('app/notification', ['switch channel failed', 'warning'], {
          root: true,
        })
      })

    // load cs agent
    const loaderCSAgent = apiClient
      .getChannelCSAgent(id)
      .then(response => {
        commit('setCSAgent', response.data)
      })
      .catch(() => {
        // set default contact
        const fallback = {
          name: 'Support',
          email: 'support@findologic.com',
          phone: null,
          photoUrl: null,
        }
        commit('setCSAgent', fallback)
      })

    // load import status
    const loaderImportStatus = apiClient
      .getReportingDataSyncHistory(id, 1)
      .then(response => {
        commit('setImportStatus', response.data[0])
      })
      .catch(() => {
        commit('app/notification', ['load data sync report', 'warning'], {
          root: true,
        })
      })

    // load google analytics
    loaderChannel.then(() => {
      if (getters.isGoogleAnalyticsConfigured) {
        dispatch('analytics/initShop', state.id, { root: true })
      }
    })

    return Promise.all([loaderChannel, loaderCSAgent, loaderImportStatus])
  },
}

const getters = {
  isGoogleAnalyticsConfigured: state => {
    return state.data?.google_analytics?.configured
  },
  isImportSucceed: state => {
    return ['SUCCESS', 'WARNING'].includes(state.importStatus?.status)
  },
  useCategoryUrl: state => {
    return (
      state.data?.integration_type === 'DI' ||
      state.data?.integration_type === 'IF'
    )
  },
  getGoogleAnalyticsStatus: (state, getter, rootState) => {
    function createStatus(content, color, label, active) {
      return { content, color, label, active }
    }

    if (window.failedToLoadGoogleClient) {
      return createStatus('?', 'grey', 'blocked', false)
    } else if (!getter.isGoogleAnalyticsConfigured) {
      return createStatus('!', 'error', 'not-connected', false)
    } else if (!rootState.analytics.connected) {
      return createStatus('!', 'warning', 'not-accessible', false)
    }

    return createStatus('✓', 'success', 'connected', true)
  },
  formatCurrency:
    (state, getters, rootState, rootGetters) => (amount, options) => {
      const symbol = state.data?.currency_symbol

      let currency
      switch (symbol) {
        case '£':
          currency = 'GBP'
          break

        default:
          currency = 'EUR'
      }

      return rootGetters['app/getNumberFormatter']({
        style: 'currency',
        currency: currency,
        ...options,
      }).format(amount)
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
