import apiClient from 'api-client'

const CLIENT_ID =
  '257559402805-8ogbk4v4im0vbcmm62i8cphhht1b0dmj.apps.googleusercontent.com'
const SCOPES = 'https://www.googleapis.com/auth/analytics.readonly'
const gapi = window.gapi

const state = () => ({
  accessToken: null,
  configured: false,
  connected: true,
  isInitialized: false,
})

const mutations = {
  setConfigured(state, active) {
    state.configured = active
  },
  setConnected(state, active) {
    state.connected = active
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
  },
  setIsInitialized(state, isInitialized) {
    state.isInitialized = isInitialized
  },
}

const actions = {
  authorize() {
    return new Promise((resolve, reject) => {
      const callback = event => {
        resolve(event.code)
      }

      const errorCallback = event => {
        reject(new Error(event.message))
      }

      const client = window.google.accounts.oauth2.initCodeClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        ux_mode: 'popup',
        callback,
        error_callback: errorCallback,
      })

      client.requestCode()
    })
  },

  signOut({ commit, state }) {
    return new Promise(resolve => {
      const done = () => {
        // Since the emitter fires only once remember that Google Analytics is ready so consumers can check
        // the status in the future.
        commit('setConfigured', false)
        commit('setConnected', false)
        commit('setAccessToken', null)

        resolve()
      }

      window.google.accounts.oauth2.revoke(state.accessToken, done)
    })
  },

  initShop({ commit }, shopId) {
    commit('setConfigured', false)
    commit('setConnected', false)
    commit('setIsInitialized', false)

    return new Promise((resolve, reject) => {
      apiClient.getGoogleAnalyticsRefreshToken(shopId).then(
        response => {
          gapi.client.setToken(response.data)
          commit('setAccessToken', response.data.access_token)

          gapi.client
            .load('https://analytics.googleapis.com/$discovery/rest')
            .then(() => {
              commit('setConfigured', true)
              commit('setConnected', true)

              resolve()
            })
            .catch(error => {
              reject(error)
            })
        },
        error => {
          reject(error)
        }
      )
    }).finally(() => {
      commit('setIsInitialized', true)
    })
  },

  // https://developers.google.com/analytics/devguides/config/mgmt/v3/mgmtReference/management/accountSummaries/list#javascript
  loadConfig({ state }) {
    return new Promise((resolve, reject) => {
      if (state.configured) {
        const request = gapi.client.analytics.management.accountSummaries.list()
        request.execute(response => {
          resolve(response)
        })
      } else {
        reject(new Error('not configured or connected'))
      }
    })
  },

  // @see https://developers.google.com/analytics/devguides/reporting/core/v3/coreDevguide#javascript
  loadData({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      if (state.configured) {
        gapi.client.analytics.data.ga
          .get(params)
          .then(response => {
            commit('setConnected', true)
            resolve(response)
          })
          .catch(error => {
            commit('setConnected', false)
            reject(error)
          })
      } else {
        reject(new Error('not configured or connected'))
      }
    })
  },

  // @see https://developers.google.com/analytics/devguides/reporting/core/v3/reference#filters
  // @see https://ga-dev-tools.appspot.com/dimensions-metrics-explorer/
  getTransactionReport({ dispatch }, params) {
    return dispatch('loadData', {
      ...params,
      metrics: 'ga:transactions,ga:transactionRevenue',
      dimensions: 'ga:transactionId,ga:date',
    }).then(response => {
      return response.result
    })
  },
}

const getters = {
  getIsInitialized(state) {
    return state.isInitialized
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
