import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'

export default Vue.use(Zendesk, {
  key: '67c165b6-3ea2-4d11-ac8b-df6b28f7c712',
  disabled: true,
  hideOnLoad: true,
  settings: {
    webWidget: {
      color: {
        theme: '#ef6315',
        launcher: '#ef6315',
        launcherText: '#fff',
      },
      contactForm: {
        subject: true,
      },
      position: { horizontal: 'left', vertical: 'bottom' },
    },
  },
})
