import { cloneDeep } from 'lodash'
import apiClient from 'api-client'
import router from '@/router'
import i18n from '@/i18n'

const defaultGuide = () => ({
  id: -1,
  name: '',
  config: {
    name: '',
    active: false,
    entryFlow: 'flow-1',
    initialCategory: null,
    title: '',
    subtitle: '',
    trigger: {
      type: '',
      value: '',
    },
    questions: [],
    branchingQuestions: [],
    flows: [
      {
        id: 'flow-1',
        name: 'Flow 1',
        questionIds: [],
      },
    ],
  },
})

const state = () => ({
  loading: false,
  guide: defaultGuide(),
})

const mutations = {
  resetGuide(state) {
    state.guide = defaultGuide()
  },
  setLoading(state, loadingState) {
    state.loading = loadingState
  },
  setGuide(state, newGuide) {
    state.guide = cloneDeep(newGuide)
  },
  setConfig(state, payload) {
    state.guide.config = {
      ...state.guide.config,
      ...payload,
    }
  },
  addQuestion(state, payload) {
    state.guide.config.questions.push(payload)
  },
  setQuestions(state, payload) {
    state.guide.config.questions = payload
  },
  addBranchingQuestion(state, payload) {
    state.guide.config.branchingQuestions.push(payload)
  },
  setBranchingQuestions(state, payload) {
    state.guide.config.branchingQuestions = payload
  },
  addFlow(state, payload) {
    state.guide.config.flows.push(payload)
  },
  setFlows(state, payload) {
    state.guide.config.flows = payload
  },
}

const actions = {
  load({ commit, state }, { channelId, guideId }) {
    if (guideId) {
      handleRequest(
        commit,
        state,
        apiClient.getShoppingGuide(channelId, guideId),
        guideId,
        true,
        true
      )
    }
  },
  save({ commit, state }, { channelId, guideId, config }) {
    const request = guideId
      ? apiClient.saveShoppingGuide(channelId, config, guideId)
      : apiClient.addShoppingGuide(channelId, config)

    handleRequest(commit, state, request, guideId)
  },
  delete({ commit }, { channelId, guideId }) {
    if (guideId) {
      commit('setLoading', true)

      apiClient
        .deleteShoppingGuide(channelId, guideId)
        .then(() => {
          router.push({ name: 'ShoppingGuide' })
          commit('resetGuide')
        })
        .catch(e => {
          commit('app/notification', [e.response.data, 'error'], {
            root: true,
          })
          commit('setLoading', false)
        })
    }
  },
}

const handleRequest = (
  commit,
  state,
  request,
  guideId,
  redirectOnError = false,
  loading = false
) => {
  commit('setLoading', true)

  request
    .then(response => {
      const redirect = state.id !== response.data.id && !guideId

      commit('setGuide', response.data)

      if (redirect) {
        router.push({
          name: 'ShoppingGuideEdit',
          params: { guideId: response.data.id },
        })
      }
    })
    .catch(error => {
      let errorMessage = i18n.t(
        loading
          ? 'common.notification.failed.loading'
          : 'common.notification.failed.saving'
      )

      if (error.response) {
        if (error.response.status === 422) {
          const errorMessages = new Set()
          Object.entries(error.response.data.errors).map(
            ([fieldName, messages]) => {
              const fieldNameWithoutArrayPosition = fieldName.replace(
                /\[.*]/,
                ''
              )
              if (messages[0].includes('NULL')) {
                errorMessages.add(
                  i18n.t(
                    `instant-frontend.shopping-guide.validation-errors.${fieldNameWithoutArrayPosition}.null`
                  )
                )
              } else if (messages[0].includes('minimum')) {
                errorMessages.add(
                  i18n.t(
                    `instant-frontend.shopping-guide.validation-errors.${fieldNameWithoutArrayPosition}.minimum-not-reached`
                  )
                )
              } else {
                errorMessages.add(messages[0])
              }
            }
          )
          errorMessage = [...errorMessages]
            .filter(message => message !== 'Failed to match all schemas')
            .join('<br>')
        }
      }

      commit('app/notification', [errorMessage, 'error'], {
        root: true,
      })

      if (redirectOnError) {
        router.push({
          name: 'ShoppingGuide',
        })
      }
    })
    .finally(() => commit('setLoading', false))
}

const getters = {
  isLoading: state => {
    return state.loading
  },
  getGuide: state => {
    return cloneDeep(state.guide)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
